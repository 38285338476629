/* global GOOGLE_ANALYTICS_UA:true */
'use strict'

import common from '../../lib/common'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-analytics'
import store from '../../store'
import { strings } from '../../lib/strings'
import Login from '../../components/Login'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../../css/normalize.css'
import '../../css/h5bp.css'
import '../../css/style.css'
import '../css/style-login.css'
import { init as initApm } from '@elastic/apm-rum'

require('es6-promise').polyfill() //required for IE 11

const sharedState = { lang: '' }
sharedState.defaultStrings = strings
sharedState.strings = strings.en
sharedState.common = common
sharedState.logClientErrorToAPM = () => {}

if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {
  // detect it's IE11
  window.addEventListener(
    'hashchange',
    function() {
      var currentPath = window.location.hash.slice(1)
      router.push(currentPath)
    },
    false
  )
}

function ariaReadText(text) {
  sharedState.textToRead = ''
  Vue.nextTick(function() {
    sharedState.textToRead = text
  })
}

Vue.prototype.$ariaReadText = ariaReadText

common
  .loadConfig('/config.json')
  .then(config => {
    Vue.set(sharedState, 'config', config)
    if (config.apmServerUrl) {
      sharedState.logClientErrorToAPM = common.setUpAPM(
        initApm({
          serviceName: 'IPRelayBrowser',
          serverUrl: config.apmServerUrl,
          serviceVersion: config.build,
          active: config.environment !== 'development',
        })
      )
    }
    if (config.css) {
      Object.keys(config.css).forEach(key => {
        sharedState.common.customSheet().insertRule(key + ' ' + config.css[key], 0)
      })
    }
    if (config.favicon) {
      document.querySelector('link[rel*="icon"]').href = config.favicon
    }
    if (config.strings) {
      Object.assign(sharedState.defaultStrings.en, config.strings.en)
      Object.assign(sharedState.defaultStrings.fr, config.strings.fr)
      sharedState.common.setLanguage(sharedState)
    }
  })
  .catch(() => {})

const id = GOOGLE_ANALYTICS_UA
Vue.use(VueAnalytics, { id })
Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    // dynamic segments start with a colon
    { path: '/:lang' },
  ],
})

store.commit({
  type: 'setSharedState',
  sharedState,
})

new Vue({
  router,
  store,
  el: '#loginApp',
  render: h => h(Login),
})
