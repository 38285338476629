<template>
  <div class="IPRelayApp">
    <IprelayHeader :title="sharedState.strings.welcome" />
    <main v-if="sharedState.config && sharedState.config.canLogin" class="main--flexed">
      <div class="login clearfix">
        <h2 v-if="sharedState.strings.welcome_new" class="center">{{ sharedState.strings.welcome_new }}</h2>
        <div class="language_link" :lang="sharedState.lang === 'fr' ? 'en' : 'fr'">
          <a :href="'#/' + sharedState.strings.switch_lang_code" class="iprelay-link color-primary">{{
            sharedState.strings.switch_lang
          }}</a>
        </div>
        <div aria-live="assertive">
          <div v-if="sharedState.invalidLogin" role="alert" class="error small">
            {{ sharedState.strings.invalid_password }}
          </div>
          <div v-else-if="sharedState.invalidSSO" role="alert" class="error small">
            {{ sharedState.strings.invalid_saml_user }}
          </div>
        </div>
        <div id="login-form" class="login-form">
          <form action="/login/" method="POST">
            <label for="username" class="block">{{ sharedState.strings.access_number }}</label>
            <input id="username" v-model="username" type="text" name="username" class="login__input" />
            <label for="password" class="block">{{ sharedState.strings.password }}</label>
            <input id="password" v-model="password" type="password" name="password" class="login__input" />

            <br />

            <input :value="sharedState.strings.log_in" type="submit" class="color-primary login-button" />
          </form>
        </div>
        <div class="password_link">
          <a href="/password" class="iprelay-link color-primary">{{ sharedState.strings.forgot_password }}</a>
        </div>
        <div v-if="sharedState.strings.register" class="registration_link">
          <a href="/register" class="iprelay-link color-primary">{{ sharedState.strings.register }}</a>
        </div>
      </div>
    </main>
    <main v-else-if="sharedState.config && !sharedState.config.canLogin">{{ sharedState.strings.error_loading }}</main>
    <IprelayFooter v-if="sharedState.config && sharedState.config.canLogin" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import IprelayFooter from './IprelayFooter'
import IprelayHeader from './IprelayHeader'

export default {
  name: 'Login',
  components: { IprelayFooter, IprelayHeader },
  data: () => {
    return {
      username: '',
      password: '',
    }
  },
  computed: {
    ...mapGetters(['sharedState']),
  },
  watch: {
    $route(to) {
      if (to.params) {
        this.sharedState.common.setLanguage(this.sharedState, to.params.lang)
      }
    },
  },
  mounted: function() {
    if (this.$route && this.$route.params) {
      if (this.$route.params.lang === 'invalid') {
        this.$set(this.sharedState, 'invalidLogin', true)
      } else if (this.$route.params.lang === 'invalidSSO') {
        this.$set(this.sharedState, 'invalidSSO', true)
      }
      this.sharedState.common.setLanguage(this.sharedState, this.$route.params.lang)
    }
  },
}
</script>
<style scoped>
.login__input {
  padding: 0.2em 0.2em;
}
</style>
